import Modal from '@trendmicro/react-modal';
import React, { LegacyRef, useEffect } from 'react';
import { Dropdown, Grid, Header, Segment, Button } from 'semantic-ui-react';
import './_ReseedPlan.scss';
import { AppState, AppThunkDispatch } from 'src/store';
import { connect } from 'react-redux';
import { SeedPlan, SeedActuals } from 'src/state/scope/ScopeManagement.slice';
import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { isEmpty, Dictionary, isUndefined } from 'lodash';
import { useCallback, useState } from 'react';
import { PlanId } from 'src/state/scope/codecs/PlanMetadata';
import { useHandleKeyPress } from 'src/utils/Component/hooks/hooks';
import AnchorRadioSelect from 'src/components/AnchorRadioSelect/AnchorRadioSelect';
import { serverScopeMemberFromId } from 'src/components/Mfp/MfpScopeSelector/MfpScopeUtils';
import { seedScope } from 'src/state/scope/Scope.actions';
import { toast } from 'react-toastify';
import { Command } from 'src/state/scope/codecs/Commands';
import { hasCommands, anchorsFromCommandDetails, getSeedCommands } from 'src/state/scope/codecs/Commands.utils';
import {
  useFocusButtonRef,
  useSelectedPlanId,
  useCurrentCommands,
  useSelectedTime,
  useSetPlanId,
  useCommandToDropdown,
  useCommandToTimeDropdown,
  useSelectedCommand,
  useHandleChangeCommand,
  useHandleChangeTime,
} from 'src/utils/Component/hooks/PlanManagement.hooks';
import { keyToCommand } from 'src/state/scope/codecs/projections/PlanMetadataToDropdown';

const mapStateToProps = (state: AppState) => {
  const { settings } = state;
  const dimensionLabel = settings.dimensionLabelProperty;
  const readyScope = getScopeReadyData(state.mfpScope);

  if (!readyScope || isEmpty(readyScope.commands)) {
    return {
      commands: undefined,
    };
  }

  // map it twice, as hasCommands doesn't return the correct type
  // filter so that we don't get empty sets of commands
  const commands = readyScope.commands
    .map(getSeedCommands)
    .filter(hasCommands)
    .map(getSeedCommands);

  return {
    commands,
    dimensionLabel,
    scope: readyScope,
    initializedPlans: readyScope.mainConfig.initializedPlans,
    anchor: serverScopeMemberFromId(anchorsFromCommandDetails(commands), readyScope.mainConfig.members),
    isMultiScope: readyScope.isMultiScope,
    entries: settings.entriesByKey
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    dispatchedSeedScope: (seed: Command['command']) => dispatch(seedScope(seed)),
  };
};
export type ReseedPlanModalProps = ReseedPlanModalValueProps & ReseedPlanModalDispatchProps & ReseedPlanModalOwnProps;
export type ReseedPlanModalValueProps = ReturnType<typeof mapStateToProps>;
export type ReseedPlanModalDispatchProps = ReturnType<typeof mapDispatchToProps>;
export interface ReseedPlanModalOwnProps {
  loading: boolean;
  onSubmit: (id?: number) => void;
  onCancel: () => void;
  onOpen: () => void;
}

// these are not actaully sorted correctly, so this might not actually be the correct choice
// TODO: get the default options in the following perference RP > OP > actuals
export const getDefaultOptionFromTime = (
  time: string,
  seeds: Dictionary<(SeedPlan | SeedActuals)[]>
): SeedPlan | SeedActuals => seeds[time].reverse()[0];
export const getPlanIdFromTimeAndName = (
  time: string,
  name: string,
  seeds: Dictionary<(SeedPlan | SeedActuals)[]>
): PlanId => {
  const foundPlanId = seeds[time].find((pln) => pln.name === name);
  if (!foundPlanId || !foundPlanId.planId) {
    throw new Error('Looked for a PlanId but couldnt find one. This shouldnt happen.');
  }
  return foundPlanId.planId;
};

const ReseedPlanModal = (props: ReseedPlanModalProps) => {
  const { dimensionLabel, commands, onCancel, onSubmit, dispatchedSeedScope, anchor, isMultiScope, entries } = props;

  const buttonRef = useFocusButtonRef();
  const [loading, setLoading] = useState(false);
  const [mutationPending, setMutationPending] = useState(false);

  const [selectedPlanId, setPlanId] = useSelectedPlanId(commands);
  const currentCommands = useCurrentCommands(commands, selectedPlanId);
  const [selectedTime, setSelectedTime] = useSelectedTime(currentCommands);
  const handleChangePlanId = useSetPlanId(commands, setPlanId);
  const versionOptions = useCommandToDropdown(commands, selectedPlanId, selectedTime, entries);
  const timeOptions = useCommandToTimeDropdown(currentCommands, entries);
  const [selectedCommand, setSelectedCommand] = useSelectedCommand(currentCommands);
  const handleChangeCommand = useHandleChangeCommand(setSelectedCommand);
  const handleChangeTime = useHandleChangeTime(selectedTime, setSelectedTime);

  useEffect(() => {
    if(isEmpty(commands) && isUndefined(anchor)){
      setLoading(false); // no data, doesn't need to remain loading
    } else if(isEmpty(commands)){
      setLoading(true);
    }
  }, [commands, anchor])

  const handleSubmit = useCallback(async () => {
    if (currentCommands && selectedCommand) {
      const maybeFoundCommand = keyToCommand(selectedCommand, currentCommands.commands);
      if (!maybeFoundCommand) {
        return;
      }
      setMutationPending(true);
      const maybeSeed = await dispatchedSeedScope(maybeFoundCommand.command);
      if (maybeSeed.type === seedScope.rejected.type) {
        // failure, toast and don't go forward
        toast.error('An error occured seeding your scope');
      } else {
        if (!isMultiScope) {
          onSubmit();
        }
      }
    }

    setMutationPending(false);
  }, [currentCommands, dispatchedSeedScope, isMultiScope, onSubmit, selectedCommand]);
  const handleEnterPress = useHandleKeyPress(handleSubmit);

  return (
    <React.Fragment>
      <div className="initialize-plan">
        <Grid columns={1} doubling={true} stretched={true}>
          <Grid.Column>
            <Segment>
              <Header as="h3" className="initialize-plan-header">
                Overwrite your WP with the selected version
              </Header>
              <p>
                This will erase your current plan values (though you are able use &quot;Undo&quot; to return your
                previous values)
              </p>
              <div>
                {commands && dimensionLabel && anchor ? (
                  <AnchorRadioSelect
                    labelDimenion={dimensionLabel}
                    anchor={anchor}
                    onUpdateAnchorSelections={handleChangePlanId}
                  />
                ) : null}
              </div>
              <div className="dropdown-group">
                <div className="dropdown-group-label">Select the Seed basis Plan Period</div>
                <Dropdown
                  fluid={true}
                  loading={loading}
                  disabled={!selectedTime}
                  scrolling={true}
                  data-qa="reseed-period-dropdown"
                  icon={<i className="chevron far fa-chevron-down icon" />}
                  options={timeOptions}
                  value={selectedTime}
                  onChange={handleChangeTime}
                />
              </div>
              <div className="dropdown-group">
                <div className="dropdown-group-label">Select the Seed Basis Plan Version</div>
                <Dropdown
                  fluid={true}
                  loading={loading}
                  disabled={!selectedCommand}
                  icon={<i className="chevron far fa-chevron-down icon" />}
                  options={versionOptions}
                  value={selectedCommand}
                  onChange={handleChangeCommand}
                />
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
      <Modal.Footer>
        <Button content="Close" onClick={onCancel} />
        <Button
          content="Submit"
          className="import-version-modal-button"
          data-qa="reseed-reseed-btn-reseed"
          onClick={handleSubmit}
          loading={mutationPending || loading}
          onKeyPress={handleEnterPress}
          // TODO idk if these types can be fixed
          ref={(buttonRef as unknown) as LegacyRef<Button>}
        />
      </Modal.Footer>
    </React.Fragment>
  );
};
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ReseedPlanModal);
