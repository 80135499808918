import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { isNil, isUndefined } from 'lodash';
import Button from '@material-ui/core/Button';
import { AppState } from 'src/store';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import styles from 'src/components/ErrorBoundary/ErrorBoundary.styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import { classes } from 'typestyle';
import service from 'src/ServiceContainer';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { zodErrorToHumanMessage } from './Erroboundary.utils';
import { AxiosError } from 'axios';
import ServiceContainer from 'src/ServiceContainer';
import { toast } from 'react-toastify';
import { ZodError } from 'zod/lib/ZodError';
interface ErrorBoundaryProps {
  error: ErrorBoundaryComponentError | null;
}

export const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const { error } = props;
  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    if (error) {
      const message = `A ${error.type} error occured while loading ${error.name}. Details: ${error.message}`;
      service.loggingService.error(message);
      if (error.stack) {
        ServiceContainer.loggingService.error(`An error occurred: ${error.stack}`);
      }
      if (error.issues) {
        const errorIssues = error.issues;
        ServiceContainer.loggingService.error(`An error occurred: ${errorIssues}`);
      }
    }
  }, [error]);

  if (isNil(error)) {
    // @ts-ignore
    return props.children;
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <section className={styles.textWrapper}>
          <div className={classes(styles.textSpacer, 'warning')}>
            {props.error
              ? `An application ${props.error.type} error has occurred`
              : `An error occured in the application `}
          </div>
          <div className={classes(styles.textSpacer, styles.textItem)}>
            You may attempt to reload the application and try again.
          </div>
          <div className={styles.textItem}> If the error persists, please contact support.</div>
        </section>
        <section className={styles.errorDropdown}>
          {error.issues ? (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Invalid Configuration Items</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {(() => {
                  if ('addIssue' in (error.issues as ZodError)) {
                    return (
                      <div>
                        <p>
                          {!isUndefined(error.defnId)
                            ? `Invalid config found at ${error.defnId} defn:`
                            : 'Invalid config found at:'}
                        </p>
                        <ul className="error-message-list">
                          {zodErrorToHumanMessage(error.issues as ZodError).map((err, idx) => {
                            return <li key={idx}>{err}</li>;
                          })}
                        </ul>
                      </div>
                    );
                  } else if (error.issues instanceof AxiosError) {
                    return (
                      <ul className="error-message-list">
                        <li>An error occured fetching data from the server</li>
                      </ul>
                    );
                  } else if (error.message.includes('invalid JSON')) {
                    return (
                      <div>
                        <p>{error.message}</p>
                      </div>
                    )
                  }
                  return (
                    <ul className="error-message-list">
                      <li>An unclassified error occured</li>
                    </ul>
                  );
                })()}
              </AccordionDetails>
            </Accordion>
          ) : null}
        </section>
        <Button
          className={styles.buttonText}
          variant="contained"
          color="secondary"
          size="large"
          startIcon={<RefreshIcon />}
          onClick={handleReload}
        >
          Reload
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state: AppState) {
  const currentError = state.error.currentError;
  return {
    error: currentError,
  };
}

export default connect(mapStateToProps)(ErrorBoundary);
